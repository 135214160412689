import { useCallback, useEffect, useState } from 'react';
import { BigNumber } from 'ethers';
import { useWeb3React } from '@web3-react/core';
import { getProvider } from "lib/rpc";
import { useChainId } from "lib/chains";

const useProviderBalance = () => {
  const [balance, setBalance] = useState(BigNumber.from(0));
  const { account, library } = useWeb3React();
  const { chainId } = useChainId();
  const provider = library || getProvider(null, chainId);
  const fetchBalance = useCallback(async () => {
            provider.getBalance(account).then((balance) => {
                const formatBalance = balance.toString();
                setBalance(formatBalance);
            });
  }, [account]);

  useEffect(() => {
    if (account) {
      fetchBalance().catch((err) => console.error(err.stack));

      const refreshBalance = setInterval(fetchBalance, 5000);

      return () => clearInterval(refreshBalance);
    }
  }, [account, setBalance, fetchBalance]);

  return balance;
};

export default useProviderBalance;
